<script>
import UserDesc from '../../components/UserDesc.vue';

export default {
  components: { UserDesc },

  name: "Home"
};
</script>

<template>
  <div v-if="$session.active" class="user-home">
    <tasks-view :userID="$session.userId" />
    <user-desc :userId="$session.userId"></user-desc>
    <!-- <user-group-desc :groupId="$session.user.groupID"></user-group-desc> -->
  </div>
</template>


<style scoped>
.user-home {
  gap: 10px;
}
</style>