<script>

export default {
  name: "UserDescription",

  props: {
    userId: {
      type: [Number, String],
      required: true
    }
  },

  data() { return {
    userDescription: "",
    waitResponse: true
  }},

  watch: {
    groupId() {
      this.RequestData();
    }
  },

  created()
  {
    this.RequestData();
  },

  methods: {
    RequestData()
    {
      if(this.userId == undefined) {
        return;
      }

      this.waitResponse = true;


      this.$api.get({ path: `users/${this.userId}/description`, }, this.OnLoad);
    },

    OnLoad({response})
    {
      const { user_desc } = response.data;
      this.userDescription = user_desc;
      this.waitResponse = false;
    },
  }
}
</script>

<template>
  <loading-box class="box user-description" v-if="waitResponse">
  </loading-box>

  <div class="box user-description" v-else>
    <h3>
      Fișa postului
    </h3>

    <div class="body">
      <pre class="rich-text" v-if="userDescription" v-html="userDescription" />
      <div v-else>
        Acest utilizator nu are fișa postului configurată.
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-description {
  width: 100%;
  overflow: hidden;
  padding: 20px;
}

.user-description {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
</style>